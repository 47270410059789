.tables {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.tables__title-block {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  gap: 32px;
}

.tables__title-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.tables__title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 133%;
  color: #ffffff;
  opacity: 0.5;
  margin: 0;
  transition: opacity 0.3s ease-in;
}

.tables__title-container_selected .tables__title {
  opacity: 1;
}

.tables__title-border {
  width: 28px;
  height: 4px;
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.3s ease-in;
}

.tables__title-container_selected .tables__title-border {
  background-color: #e874eb;
}

.tables__table-container {
  width: 100%;
  max-height: 440px;
  position: relative;
  overflow: auto !important;
  background: #21222d;
  border-radius: 24px;
  margin-top: 24px;
  z-index: 10;
}


.tables__table-body-preloader {
  height: 300px;

}

.tables__table-body-preloader-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tables__table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.tables__table-container::-webkit-scrollbar-track {
  border: none;
  outline: none;
  background-color: transparent !important;
  border-radius: 6px;
}

.tables__table-container::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  outline: none;
  background: #454751;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 6px;
}

.tables__table-container::-webkit-scrollbar-corner {
  background-color: transparent !important;
  border: none;
  outline: none;
}

.tables__table {
  width: 100%;
  text-align: left;
  border: none;
  border-collapse: collapse;
}

.tables__table-head {
  position: sticky;
  top: 0;
}

.tables__table-tr-head {
  background: #2a2c3c;
}

.tables__table-th-head {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px;
}

.tables__table-th-head_type_bet-size {
  text-align: right;
}

.tables__table-th-head_type_payout {
  text-align: right;
}

.tables__table-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  margin: 0;
}

.tables__table-tr-body {
  background: #21222d;

  transition: background-color 1s linear;
}

.tables__table-tr-body:nth-child(even) {
  background: #242632;
}


.tables__table-tr-body_new {
  background: #33343E;
}

.tables__table-tr-body_new:nth-child(even) {
  background: #363742;
}

.tables__table-tr-body_new-current-user {
  background: #503C61;
}

.tables__table-tr-body_new-current-user:nth-child(even) {
  background: #503C61;
}

.tables__table-tr-body_type_not-found {
  text-align: center;
}

.tables__table-td-body {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px;
}

.tables__table-td-body_type_time {
  width: 105px;
}

.tables__table-td-body_type_player {
  width: 115px;
}

.tables__table-td-body_type_transaction {
  width: 115px;
}

.tables__table-td-body_type_bet {
  width: 105px;
}

.tables__table-td-body_type_result {
  width: 105px;
}

.tables__table-cell {
  display: flex;
  align-items: center;
}

.tables__table-cell_type_bet-size {
  justify-content: flex-end;
}

.tables__table-cell_type_payout {
  justify-content: flex-end;
}

.tables__table-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  white-space: nowrap;
  margin: 0;
}

.tables__table-text_type_range {
  padding-right: 4px;
}

.tables__id-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129%;
  color: #5469dd;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  /* cursor: pointer; */
  transition: color 0.3s ease-in;
}

/* .tables__id-text:hover {
  color: #7e92ff;
} */

.tables__id-text_disabled {
  color: rgba(84, 105, 221, 0.44);
  pointer-events: none;
}

.tables__coin-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
}

.tables__money-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129%;
  text-align: right;
  white-space: nowrap;
  margin: 0;
  padding-right: 4px;
}

.tables__money-text_type_green {
  color: #40cf71;
}

.tables__currency-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  object-position: center;
}

.tables__not-found {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  margin: 0;
  padding: 50px 0;
}


@media (max-width: 1050px) {
  .tables__title-container {
    padding-left: 24px;
  }
}

@media (max-width: 550px) {
  .tables__id-text:hover {
    color: #5469dd;
  }
  .tables__title-container {
    padding-left: 16px;
  }
}