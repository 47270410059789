.header {
  width: 100%;
  height: 64px;
  position: relative;
  /* background: #171821; */
  /* border-bottom: 1px solid #393a40; */
  z-index: 1000;

}

.header__container {
  width: 100%;
  max-width: 1150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 32px;
  margin: 0 auto;
}

.header__sound-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.header__sound-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #202228;
  border-radius: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.sound-icon {
  width: 16px;
  height: 11px;
}

.header__burger-button {
  display: none;
}

.header__info-block {
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  align-items: center;
  gap: 16px;
}

.header__info-block_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.header__demo-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.header__demo-block_user {
  grid-template-columns: 1fr;
}

.header__demo-box {
  display: flex;
  align-items: center;
  background: #202228;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 7px;
  text-decoration: none;
  color: #ffffff;
}

.header__currency-icon {
  width: 18px;
  height: 18px;
}

.header__demo-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  margin: 0;
  padding-left: 6px;
}

.header__login-btn {
  display: flex;
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: #ffffff;
  background-image: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  padding: 7px 12px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in;
}

.header__login-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 12px;
  box-shadow: 0px 0px 0px 2px rgba(174, 84, 250, 0.24);
  transition: opacity 0.3s ease-in;
}

.header__login-btn:hover::after {
  opacity: 1;
}

.header__box-with-drop {
  position: relative;
}

.header__user-box {
  min-width: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 12px;
  background: linear-gradient(#171821, #171821),
    linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-sizing: border-box;
  padding: 7px 12px;
  cursor: pointer;
}

.header__user-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  background: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  padding-right: 2px;
}

.header__arrow-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in;
}

.header__arrow-icon_reverse {
  transform: rotate(180deg);
}

.header__select-popup_opened .header__select-overlay {
  width: 100vw;
}

.header__select-overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.header__select-popup-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #242632;
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  padding: 7px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 8px);
  z-index: 100;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.header__select-popup-list-item {

  margin: 0;
  padding: 4px;
  column-gap: 12px;
  border-radius: 8px;
  background: none;
  border: none;
  outline: none;
  display: grid;
  grid-template-columns: minmax(0, 18px) 1fr;
  grid-template-rows: max-content;
  justify-content: start;
  align-content: start;
  cursor: pointer;
}

.header__select-popup-list-item_selected {
  background-color: #9747ff;
  user-select: none;
  pointer-events: none;
}

.header__select-popup-list-item-icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__select-popup-list-item-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: #ffffff;
  padding: 0;
  margin: 0;
  text-align: left;
}

.header__select-popup_opened .header__select-popup-list {
  visibility: visible;
  opacity: 1;
}



.header__drop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  background: #202228;
  border-radius: 12px;
  gap: 6px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

}

.header__drop-box-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  gap: 6px;
  cursor: pointer;
  cursor: pointer;
  white-space: nowrap;
}

.header__drop-inside {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.header__drop-soon {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 6px;
  box-sizing: border-box;

  background: #E874EB;
  border-radius: 16px;
  margin: 0 0 0 auto;


}

.header__drop-box-item_inactive {
  opacity: 0.3;
  pointer-events: none;
}

.header__drop-arrow {
  width: 16px;
  height: 16px;
  transition: all 0.2s linear;
}

.header__drop-container {
  position: relative;
}

.header__drop-arrow_open {
  transform: rotate(180deg);
}

.header__drop-box {
  min-width: 100%;
  position: absolute;
  top: calc(100% + 16px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: #202228;
  /* Shadow */

  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 16px;

  box-sizing: border-box;
}

.header__select-network-box {
  position: relative;
}

.header__current-network {
  min-width: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 7px 12px;
  cursor: pointer;
  background-color: #171821;
  gap: 8px;
}

.header__current-network-name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  background: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  padding-right: 2px;
}

.header__current-network-logo {
  width: 18px;
  height: 18px;
  object-fit: contain;
}


@media (max-width: 1150px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
  }

  .header__sound-block {
    display: none;
  }

  .header__burger-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
  }

  .header__burger-button:hover {
    background: rgba(255, 255, 255, 0.04);
  }
}

@media (max-width: 550px) {
  .header__container {
    padding: 16px;
  }

  .header__burger-button:hover {
    background: transparent;
  }

  .header__info-block {
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    gap: 8px;
  }

  .header__demo-block {
    display: none;
  }

  .header__login-btn:hover {
    background-image: linear-gradient(92.29deg,
        #9747ff -42.66%,
        #d76bf0 118.44%);
  }

  .header__current-network-name {
    font-size: 12px;
  }

  .header__user-box{
    min-width: unset;
  }

  .header__user-text {
    font-size: 12px;
  }

  .header__select-popup-list-item {
    gap: 6px;
  }

  .header__select-popup-list-item-icon {
    width: 14px;
    height: 14px;
  }

  .header__current-network-logo {
    width: 14px;
    height: 14px;
  }
  .header__current-network{
    gap: 6px;
    min-width: unset;
  }

  .header__select-popup-list-item-text {
    font-size: 12px;
  }
}