.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 164px;
}

@media (max-width: 710px) {
  .main {
    padding: 0 0 40px;
  }
}
