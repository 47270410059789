.luckyWheel {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
}


.luckyWheel__table-block {
  width: 100%;
  margin-top: 64px;
}

@media (max-width: 1150px) {
  .luckyWheel {
    padding: 0 0 64px;
  }


}

@media (max-width: 900px) {
  .luckyWheel {
    padding: 0 0 64px;
  }


}

@media (max-width: 730px) {
  .luckyWheel {
    padding: 0 0 64px;
  }
}


@media (max-width: 550px) {
  .luckyWheel {
    padding: 0 0 64px;
  }


}