.banner {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 40px 32px 48px;
  margin: 0 auto;
}

.banner__title-box {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
}

.banner__title {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 117%;
  letter-spacing: 0.01em;
  margin: 0;
}

.banner__title_type_gradient {
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background: -webkit-linear-gradient(
    45deg,
    rgba(170, 82, 251, 1),
    rgba(249, 242, 255, 1)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-fill-color: #171821;
}

.banner__subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  padding-top: 16px;
}

.banner__subtitle_type_return {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffe70a;
  background: rgba(57, 52, 71, 0.25);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 6px 12px;
  margin-left: 10px;
}

.banner__image {
  width: 808px;
  height: 538px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: -74px;
  right: -175px;
  z-index: -1;
}

.banner__back-ellipse {
  width: 1005px;
  height: 1005px;
  background: radial-gradient(50% 50% at 50% 50%, #513855 0%, #171821 100%);
  position: absolute;
  top: -360px;
  right: -580px;
  z-index: -2;
}

@media (max-width: 1150px) {
  .banner {
    padding: 88px 32px 96px;
  }

  .banner__subtitle {
    padding-top: 20px;
  }

  .banner__image {
    top: -26px;
    right: -80px;
  }

  .banner__back-ellipse {
    top: -312px;
    right: -546px;
  }
}

@media (max-width: 1040px) {
  .banner__image {
    right: -175px;
  }
}

@media (max-width: 900px) {
  .banner {
    padding: 68px 16px 0;
  }

  .banner__title {
    font-size: 36px;
  }

  .banner__image {
    width: 688px;
    height: 482px;
    top: -46px;
    right: -150px;
  }
}

@media (max-width: 710px) {
  .banner__title {
    font-size: 32px;
  }

  .banner__image {
    width: 568px;
    height: 427px;
    right: -120px;
  }
}

@media (max-width: 620px) {
  .banner {
    padding: 268px 16px 0;
  }

  .banner__title {
    line-height: 175%;
  }

  .banner__subtitle {
    font-size: 14px;
    line-height: 200%;
    padding-top: 20px;
  }

  .banner__image {
    top: -61px;
    right: -50px;
  }
}

@media (max-width: 460px) {
  .banner__image {
    right: -100px;
  }
}
