:root {
  --toastify-color-dark: #2a2c3c;
  --toastify-font-family: "Rubik";
  --toastify-toast-width: 234px;
  --toastify-toast-min-height: unset;
  --toastify-color-progress-dark: transparent;
}

.app {
  width: 100%;
  /* max-width: 1500px; */
  min-height: 100vh;
  display: grid;
  grid-template-columns: 260px 1fr;
  margin: 0 auto;
}

.app__content {
  width: 100%;
  overflow: hidden;
}

.app__confirm-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  padding: 0 16px;
  z-index: 10000;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.app__confirm-popup_opened {
  visibility: visible;
  opacity: 1;
}

.app__popup-overlay {
  width: 0;
  height: 100vh;
  background: #000000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.app__confirm-popup_opened .app__popup-overlay {
  width: 100vw;
  opacity: 0.7;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.app__popup-container {
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #171821;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 24px;
  overflow: hidden;
  visibility: hidden;
  z-index: 10000;
  transition: visibility 0.3s ease-in-out;
}

.app__confirm-popup_opened .app__popup-container {
  visibility: visible;
}

.app__popup-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #fffafa;
  text-align: center;
  margin: 0;
}

.app__confirm-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  padding: 12px;
  margin-top: 24px;
  cursor: pointer;
}

.Toastify__toast-container {
  max-width: 288px;
  padding: 0;
}

.Toastify__toast-container--top-right {
  top: 75px;
  right: 34px;
}

.Toastify__toast {
  height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 5px;
}

.Toastify__toast-body {
  align-items: flex-start;
  padding: 0;
}

.toast_type_copy .Toastify__toast-body {
  align-items: center;
}

.Toastify__toast-icon {
  padding: 2px;
  margin-inline-end: 8px;
}

.Toastify__progress-bar {
  height: 0;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.6s ease-out both;
  animation: slide-in-right 0.6s ease-out both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fade-out {
  -webkit-animation: fade-out 2s ease-out both;
  animation: fade-out 2s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1150px) {
  .app {
    grid-template-columns: 1fr;
  }

  .app__content {
    box-sizing: border-box;
    padding-top: 64px;
  }

  .app__overlay {
    width: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #13151b;
    opacity: 0;
    z-index: 109;
    transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
  }

  .app__overlay_opened {
    width: 100vw;
    opacity: 0.5;
    transition: width 0s ease 0s, opacity 0.4s ease-in-out;
  }
}

@media only screen and (max-width: 730px) {
  .app_toast-container_type_game.Toastify__toast-container--top-right {
    top: 274px;
    right: 16px;
  }

  .Toastify__toast-container--top-right {
    right: 16px;
  }
}

@media only screen and (max-width: 550px) {
  .Toastify__toast-container {
    left: unset;
  }

  .app_toast-container_type_game.Toastify__toast-container--top-right {
    top: 214px;
  }

  .Toastify__toast {
    border-radius: 8px;
    padding: 6px;
  }

  .Toastify__toast-icon {
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    padding: 1px;
  }
}
