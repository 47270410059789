.about {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 64px auto 0;
}

.about__title {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 125%;
  margin: 0;
}

.about__text {
  max-width: 400px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  padding-top: 16px;
}

.about__image {
  width: 428px;
  height: 356px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  right: 28px;
  top: -55px;
}

@media (max-width: 1150px) {
  .about__text {
    max-width: 440px;
  }

  .about__image {
    right: 68px;
  }
}

@media (max-width: 1040px) {
  .about__image {
    right: 28px;
  }
}

@media (max-width: 900px) {
  .about {
    padding: 0 16px;
  }

  .about__title {
    font-size: 28px;
    line-height: 157%;
  }

  .about__text {
    max-width: 400px;
  }

  .about__image {
    width: 358px;
    height: 302px;
    right: 0;
    top: -35px;
  }
}

@media (max-width: 720px) {
  .about__text {
    max-width: 360px;
  }

  .about__image {
    width: 288px;
    height: 251px;
    top: -0px;
  }
}

@media (max-width: 710px) {
  .about {
    margin: 40px auto 0;
  }
}

@media (max-width: 600px) {
  .about__text {
    max-width: 100%;
  }

  .about__image {
    position: static;
    margin: 16px auto 0;
  }
}
